<script>
	import Layout from '../../layouts/main'
	import PageHeader from '@/components/page-header'
	import appConfig from '@/app.config'
	import Multiselect from 'vue-multiselect'

	export default {
		page: {
			title: '',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Salary Sheet",
						active: true,
					},
				],
				corporateId: '',
				loading: false,
				branch: '',
				salaryFile: null,
				branchs: [],
				uploadProgress: 0,
				uploading: false,
				downloadUrl: '',
			};
		},
		mounted: function() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getCorporateBranch()
		},
		methods: {
			getCorporateBranch: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId
				}).then((result) => {
					this.branchs = [];
					for(let i = 0; i < result.data.data.length;i++) {
						this.branchs.push({
							branchId: result.data.data[i]._id,
							name: result.data.data[i].name,
						});
					}
					this.branch = this.branchs[0]
				}).catch((error) => {
					console.log(error)
				});
			},
			handleFileUpload(event) {
				this.salaryFile = event.target.files[0];
			},
			addSalary: function() {
				this.uploading = true;
				let formData = new FormData();
				formData.append('file', this.salaryFile);
				formData.append('branchId', this.branch.branchId);
				formData.append('corporateId', (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId);
				this.axios.post('addSalary', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: function(progress) {
						this.uploadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100));
					}.bind(this),
				}).then((response) => {
					this.uploading = false;
					console.log(response.data);
					let statusCode = response.data.responseCode
					if (statusCode === 200) {
						window.location.reload();
					}
					if (statusCode === 400) {
						this.$toast.error(response.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				});
			},
		},
	};
</script>
<style></style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Add Salery Sheet</h4>
						<!-- file upload -->
						<div class="p-2 mt-4">
							<div class="dowanload-example mb-2">
								<a :href="downloadUrl">Click here to download sample excel</a>
							</div>
							<b-form @submit.prevent="addEmployee($event)" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-6">
										<b-form-group class="mb-3" label="Branch" for="branch">
											<multiselect id="branch"
												:options="branchs"
												v-model="branch"
												class="helo"
												placeholder="Select Branch"
												track-by="name"
												label="name"
												:allow-empty="false"
												:multiple="false">
												<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
											</multiselect>
										</b-form-group>
									</div>
									<div class="col-lg-6">
										<b-form-group class="mb-3" label="Select .xlxs, .xls file" for="resume">
											<b-form-file id="resume"
												v-model="salaryFile"
												class="form-control-file"
												placeholder="Select file" @change="handleFileUpload($event)" plain></b-form-file>
										</b-form-group>
									</div>
									<div class="mt-3 text-end">
										<progress max="100" animated="true" striped="true" v-if="uploading" :value.prop="uploadProgress"></progress>
										<b-button class="btn-btn-outline-primary mt-4" v-if="!uploading" type="submit" @click="addSalary" variant="primary">Submit</b-button>
									</div>
								</div>
							</b-form>
						</div>
					</div>
					<!-- end card-body -->
				</div>
			</div>
		</div>
	</Layout>
</template>